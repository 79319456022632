<template>
    <div>
        <form class="card" @submit.prevent.stop="save()">
            <div class="row">
                <!-- firstname-->
                <div class="form-group col-md-6">
                    <div class="control">
                        <label for="middleName"> Name </label>
                        <input
                            v-model="formData.name"
                            v-validate="'required'"
                            type="text"
                            class="form-control"
                            placeholder="firstname"
                            :readonly="disabled"
                            name="name"
                        >
                        <span class="text-danger">{{ errors.first("name") }}</span>
                    </div>
                </div>
                <!-- End of firstname-->

                <!-- lastname-->
                <div class="form-group col-md-6">
                    <div class="control">
                        <label for="middleName"> Source Name </label>
                        <input
                            v-model="formData.source_name"
                            v-validate="'required'"
                            type="text"
                            class="form-control"
                            placeholder="Doe"
                            :readonly="disabled"
                            name="sourceName"
                        >
                        <span class="text-danger">{{ errors.first("sourceName") }}</span>
                    </div>
                </div>
                <!-- End of firstname-->

                <!-- Agent -->
                <div class="form-group col-md-6">
                    <div class="control">
                        <label for="middleName"> Agent </label>
                        <resource-selector
                            v-model="formData.agents"
                            v-validate="'required'"
                            :resource="formData.agents"
                            name="agents"
                            :searchable-by="['name', 'id']"
                            resource-url="/users"
                            :disabled="disabled"
                            resource-id="id"
                            track-by="id"
                            placeholder="Select an agent"
                            :custom-label="userLabel"
                        />
                        <span class="text-danger">{{ errors.first("agents") }}</span>
                    </div>
                </div>
                <!-- End of Agent-->

                <!-- uuid -->
                <div v-if="formData.uuid" class="form-group col-md-6">
                    <div class="control">
                        <label for="middleName"> UUID </label>
                        <input
                            v-model="formData.uuid"
                            v-validate="'required'"
                            type="text"
                            class="form-control"
                            placeholder=""
                            name="uuid"
                            readonly
                        >
                    </div>
                </div>
                <!-- End of uuids-->

                <!-- Rotations -->
                <div class="form-group col-md-6">
                    <label for="rotations"> Rotations </label>
                    <resource-selector
                        v-model="formData.rotations"
                        v-validate="'required'"
                        :class="{invalid: errors.first('rotations')}"
                        :resource="formData.rotations"
                        name="rotations"
                        label="name"
                        track-by="id"
                        :searchable-by="['name', 'id']"
                        resource-url="/leads-rotations"
                        :disabled="disabled"
                        resource-id="id"
                        placeholder="Select a rotation"
                    />
                    <span class="text-danger">{{ errors.first("rotations") }}</span>
                </div>
                <!-- End of Rotations -->

                <div v-if="!disabled" class="col-md-6 offset-md-6">
                    <div class="actions d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click.prevent="$emit('cancel')">
                            Cancel
                        </button>
                        <button class="btn btn-primary" :disabled="isLoading" @click="save()">
                            {{ createButtonText }}
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div v-if="isEditing" class="mt-5">
            <h3> Performance</h3>
            <div class="row mt-4">
                <div v-for="item in upcommingReleases" :key="item.title" class="col-md-4">
                    <div class="card padding-20 performance-card">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="main-label">
                                    {{ item.title }}
                                </div>
                                <div class="">
                                    {{ item.subtitle }}
                                </div>
                            </div>

                            <div class="col-md-6 text-right p">
                                <div class="mr-4">
                                    <span class="perf-item__value ext-dot">
                                        {{ item.value }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ResourceSelector from "@c/resource-selector";
import listMixins from "@/mixins/listMixins";

export default {
    components: {
        ResourceSelector
    },
    mixins: [
        listMixins
    ],
    props: {
        formData: {
            type: Object,
            default() {
                return {
                    agents: {},
                    rotations: {}
                }
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: false,
            endpoint: "/leads-receivers",
            upcommingReleases: [
                {
                    title: "Won Leads",
                    subtitle: "",
                    value: "7"
                },
                {
                    title: "Received Leads",
                    subtitle: "",
                    value: "12"
                },
                {
                    title: "Lost Leads",
                    subtitle: "",
                    value: "5"
                }
            ],
            branchesFields: [{
                name: "source_username",
                title: "Device",
                sortField: "source_username",
                width: "30%"
            }, {
                name: "actions",
                title: "Actions",
                titleClass: "table-actions",
                dataClass: "table-actions"
            }],
            appendParams:{
                format: "true",
                q: "(is_deleted:0)"
            },
            resource: {
                name: "rotations",
                slug: "rotations"
            }

        }
    },
    computed: {
        ...mapState({
            company: state => state.Company.data,
            user: state => state.User.data
        }),
        createButtonText() {
            return this.formData.id ? "Save Changes" : "Create"
        }
    },
    mounted() {
        if (this.isEditing) {
            this.getLeadsRecieverStats(this.formData.uuid);
        }
    },
    methods: {
        onSuccess(record) {
            this.$emit("saved", record);
        },

        async save() {
            const isValid = await this.$validator.validateAll()
            let method = "POST";
            let url = this.endpoint;

            if (!isValid) {
                this.focusFirstError(false)
                return;
            }

            const formData = { ...this.formData }
            formData.companies_id = this.company.id;
            formData.users_id = this.user.id
            formData.agents_id = this.formData.agents.id
            formData.rotations_id = formData.rotations ? formData.rotations.id : null;

            if (formData.id) {
                method = "PUT"
                url += `/${formData.id}`
            }

            if (!this.isLoading) {
                this.isLoading = true;
                axios({
                    url: url,
                    data: formData,
                    method: method
                })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.$notify({
                            title: "Success",
                            text: "Lead receiver has been saved",
                            type: "success"
                        });
                        this.onSuccess(data);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: "Error",
                            text: error.response ? error.response.data.errors.message : "Error",
                            type: "error"
                        });
                        return false;
                    })
                    .finally((result) => {
                        this.isLoading = false;
                        return result;
                    })
            }

        },

        userLabel(user) {
            return user ? `${user.firstname || ""} ${user.lastname || ""}` : ""
        },

        addRotation(rotation) {
            if (!this.formData.rotations.length) {
                this.formData.rotations.unshift(rotation);
            } else {
                this.formData.rotations[0] = rotation;
            }
        },

        deleteRotation(id) {
            this.formData.rotations = this.formData.rotations.filter((rotation) => {
                return rotation.id != id
            })
        },

        getLeadsRecieverStats(uuid) {
            axios.get('/receivers/'+uuid+'/stats')
            .then(response => {
                this.upcommingReleases = response.data;
            }).catch(error => {
                
            });
        }

    }
}
</script>

<style lang="scss">
[readonly] {
    color: #333 !important;
}
.perf-card {
    min-height: 52px;
}

.perf-item__value {
    font-weight: bolder;
    font-size: 2rem;
}

.main-label {
    font-weight: bolder;
    font-size: 1.2em;
}

.ext-dot {
    position: relative;

   &::after {
        position: absolute;
        content: '';
        height: 10px;
        width: 10px;
        top: calc(50% - 4px);
        background: green;
        border-radius: 100%;
        right: -20px;
    }
}
</style>
