<template>
    <div class="container-fluid container-fixed-lg leads">
        <h1 class="section-title p-l-10">
            LEADS RECEIVERS
        </h1>
        <div v-if="isEditing" class="row mb-2">
            <div class="col">
                <div class="client-name">
                    <h2>
                        {{ leadName }}
                        <button
                            v-if="!editMode"
                            class="btn btn-primary rounded-pill pl-4 pr-4 ml-4 btn-custom-pill"
                            @click.prevent="editMode = !editMode"
                        >
                            <i class="fa mr-2 fa-pencil" />
                        </button>
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <leads-receivers-form
                    :form-data="formData"
                    :disabled="!editMode"
                    :is-editing="isEditing"
                    @saved="onSaved"
                    @cancel="onCancel"
                />
            </div>
        </div>
    </div>
</template>

<script>
import LeadsReceiversForm from "./form";

export default {
    name: "LeadsRecievers",
    components: {
        LeadsReceiversForm
    },
    props: {
        isEditing: {
            type: Boolean
        }
    },
    data() {
        return {
            endpoint: "leads-receivers",
            editMode: true,
            formData: {
                agents: null,
                rotations: null
            }
        }
    },
    computed: {
        leadName() {
            return this.formData.name
        },
        creationMode() {
            return this.isEditing || this.editMode;
        }
    },
    watch: {
        "$route.params.id": {
            handler(id) {
                if (id) {
                    this.getRecord();
                    return;
                }
            },
            immediate: true
        }
    },
    methods: {
        onSaved(record) {
            if (!this.isEditing) {
                this.$router.replace({
                    name: "edit-resource",
                    params: {
                        resource: "leads-receivers",
                        id: record.id
                    } });
            } else {
                this.getRecord(record.id);
                this.editMode = false;
            }
        },

        onCancel() {
            if (!this.isEditing) {
                this.$router.replace({
                    name: "browse",
                    params: {
                        resource: "leads-receivers"
                    } });
            } else {
                this.editMode = false;
            }
        },

        getRecord(id) {
            axios({
                url: `${this.endpoint}/${id || this.$route.params.id}?relationships=agents,users,rotations`
            }).then(async({ data }) => {
                if (data) {
                    const formData = { ...data }
                    formData.agents = await axios(`users/${formData.agents_id}`).then(({ data }) => data);
                    formData.rotations = formData.rotations ? [formData.rotations] : [];
                    this.formData = { ...formData }
                    this.editMode = false;
                }
            })
        }
    }
}
</script>
